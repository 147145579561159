import { Box, Container, Grid, Link, useMediaQuery } from '@chakra-ui/react';
import ProductListSwiper from 'components/products/ProductListSwiper';
import { IPaginationResponse } from 'dtos/pagination.dto';
import useCatchError from 'hooks/useCatchError';
import useProductCategories from 'hooks/useProductCategories';
import useProductMainCategories from 'hooks/useProductMainCategories';
import { IProductCategorySection } from 'interfaces/home.interface';
import { IProductCategory } from 'interfaces/product-category';
import { IProduct } from 'interfaces/product.interface';
import Image from 'next/image';
import NextLink from 'next/link';
import { useEffect, useState } from 'react';
import { useProductCategoryStore } from 'store/product-category.store';
import useSWR from 'swr';

type HomeProductCategorySectionProps = {
  section: IProductCategorySection;
  hideVerticalOnMobile?: boolean;
  productType?: 'smart-home' | 'decoration' | 'lighting';
};

function HomeProductCategorySection({
  section,
  hideVerticalOnMobile = false,
  productType = 'lighting',
}: HomeProductCategorySectionProps) {
  // catch error hook
  const catchError = useCatchError();

  // use product categories
  const { productCategories } = useProductCategories();

  // use product main categories
  const { productMainCategories } = useProductMainCategories();

  // use media query
  const [isMobile] = useMediaQuery('(max-width: 767px)');

  // get product form swr
  const { data: productData } = useSWR<IPaginationResponse<IProduct>>(
    section.products && section.products.length > 0
      ? `${
          process.env.NEXT_PUBLIC_API_URL
        }/v1/public/products?ids=${section.products?.join(',')}&limit=100`
      : null
  );

  // current category
  const [currentCategory, setCurrentCategory] = useState<IProductCategory>();

  useEffect(() => {
    if (productCategories.length > 0 && section) {
      // get categories by id
      const _currentCategory = productCategories.find(
        (category) => category.id === section.category
      );

      // log category
      console.log('_currentCategory', _currentCategory);

      setCurrentCategory(_currentCategory);
    }
  }, [productCategories, section]);

  // log products
  console.log('productData', productData);

  return (
    <>
      {section.layout === 'vertical' ? (
        <>
          {isMobile && hideVerticalOnMobile ? (
            <></>
          ) : (
            <Container
              maxW="900px"
              py={{
                base: 4,
                lg: 0,
              }}
              bg="white"
              mb={{
                base: 5,
                lg: 10,
              }}
            >
              <Grid
                templateColumns={{
                  base: 'minmax(0, 1fr)',
                  md: 'minmax(0, 315px) minmax(0, 1fr)',
                  lg: 'minmax(0, 323px) minmax(0, 1fr)',
                }}
                columnGap={{
                  md: '25px',
                  lg: '40px',
                }}
              >
                {section.mediaMobile && section.mediaMobile.name && (
                  <Box
                    position="relative"
                    mb={8}
                    display={{
                      md: 'none',
                    }}
                  >
                    <NextLink
                      href={`/product-categories/${
                        productMainCategories.find(
                          (category) => category.id === section.category
                        )?.permalink ?? ''
                      }/${
                        currentCategory?.permalink ?? ''
                      }?productType=${productType}`}
                    >
                      <Image
                        src={
                          `${process.env.NEXT_PUBLIC_FILE_URL}/${section.mediaMobile.name}` ??
                          '/image-icon/empty-image.png'
                        }
                        alt={section.mediaMobile.name}
                        quality={90}
                        width={450}
                        height={385}
                      ></Image>
                    </NextLink>
                  </Box>
                )}
                {section.mediaDesktop && section.mediaDesktop.name && (
                  <Box
                    height="100%"
                    width="100%"
                    pb={1}
                    display={{
                      base: 'none',
                      md: 'block',
                    }}
                  >
                    <Box height="100%" width="100%" position="relative">
                      <NextLink
                        href={`/product-categories/${
                          productMainCategories.find(
                            (category) => category.id === section.category
                          )?.permalink ?? ''
                        }/${
                          currentCategory?.permalink ?? ''
                        }?productType=${productType}`}
                        passHref
                        legacyBehavior
                      >
                        <Link height="100%" width="100%">
                          <Image
                            src={
                              `${process.env.NEXT_PUBLIC_FILE_URL}/${section.mediaDesktop.name}` ??
                              '/image-icon/empty-image.png'
                            }
                            alt={section.mediaDesktop.name}
                            quality={90}
                            width={450}
                            height={385}
                          ></Image>
                        </Link>
                      </NextLink>
                    </Box>
                  </Box>
                )}
                <Box flex={1}>
                  <ProductListSwiper
                    products={
                      productData && productData.docs.length > 0
                        ? productData.docs
                        : []
                    }
                    navigation={false}
                    breakpoints={{
                      300: {
                        slidesPerView: 2.5,
                        spaceBetween: 10,
                      },
                      768: {
                        slidesPerView: 2.5,
                        spaceBetween: 20,
                      },
                      992: {
                        slidesPerView: 3,
                        spaceBetween: 30,
                      },
                    }}
                  ></ProductListSwiper>
                </Box>
              </Grid>
            </Container>
          )}
        </>
      ) : (
        <Container
          maxW="900px"
          py={{
            base: 4,
            lg: 0,
          }}
          bg="white"
          mb={{
            base: 5,
            lg: 10,
          }}
        >
          {section.mediaDesktop && section.mediaDesktop.name && (
            <Box
              position="relative"
              mb={10}
              display={{
                base: 'none',
                md: 'block',
              }}
            >
              <NextLink
                href={`/product-categories/${
                  productMainCategories.find(
                    (category) => category.id === section.category
                  )?.permalink ?? ''
                }/${
                  currentCategory?.permalink ?? ''
                }?productType=${productType}`}
              >
                <Image
                  src={`${process.env.NEXT_PUBLIC_FILE_URL}/${section.mediaDesktop.name}`}
                  alt={section.mediaDesktop.name}
                  width={1230}
                  height={190}
                  quality={90}
                ></Image>
              </NextLink>
            </Box>
          )}
          {section.mediaMobile && section.mediaMobile.name && (
            <Box
              position="relative"
              mb={6}
              display={{
                base: 'block',
                md: 'none',
              }}
            >
              <NextLink
                href={`/product-categories/${
                  productMainCategories.find(
                    (category) => category.id === section.category
                  )?.permalink ?? ''
                }/${
                  currentCategory?.permalink ?? ''
                }?productType=${productType}`}
              >
                <Image
                  src={
                    `${process.env.NEXT_PUBLIC_FILE_URL}/${section.mediaMobile.name}` ??
                    '/image-icon/empty-image.png'
                  }
                  alt={section.mediaMobile.name}
                  width={1230}
                  height={190}
                  quality={90}
                ></Image>
              </NextLink>
            </Box>
          )}
          <Box
            mx={{
              base: -4,
              md: 0,
            }}
            pl={{
              base: 4,
              md: 0,
            }}
          >
            <ProductListSwiper
              products={
                productData && productData.docs.length > 0
                  ? productData.docs
                  : []
              }
              breakpoints={{
                300: {
                  slidesPerView: 2.5,
                  spaceBetween: 10,
                },
                768: {
                  slidesPerView: 4.5,
                  spaceBetween: 20,
                },
                992: {
                  slidesPerView: 5,
                  spaceBetween: 30,
                },
              }}
            ></ProductListSwiper>
          </Box>
        </Container>
      )}
    </>
  );
}

export default HomeProductCategorySection;
